import React from 'react'
import pwaIcon from '../../assets/qeepPwaIcon.svg';
import './PwaInstallationIosPopup.css';

export const PwaInstallationIosPopup = ({ closeIosConfirmationPopup }) => {
  return (
    <>
    <div className="overlay-pwa-installation">
      <div className="box-pwa-installation">
        <div className="pwa-header-outer">
          <p>Install QeepLive</p>
        </div>
        <div className="pwa-installation-top-image">
          <img src={pwaIcon} />
        </div>
        <div className="text-outer-pwa-installation">
          <p className="text-pwa-installation">
          Install QeepLive app for a better experience On your mobile device.
          </p>
          <p className="text-pwa-installation-bottomEnd ">
          To install, tap the share button in browser and then Add to Home Screen.
          </p>
        </div>
        <div className="button-group-pwa-installation mt-2">
          <div className="confirm-button-pwa-installation-start" onClick={closeIosConfirmationPopup}>
            <p className="confirm-text-pwa-installation-start">Later</p>
          </div>
          <div className="confirm-button-pwa-installation-end" onClick={closeIosConfirmationPopup}>
            <p className="confirm-text-pwa-installation-end">Got it</p>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}
