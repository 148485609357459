import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProfileService } from '../services/ProfileService';

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [toastNotification, setToastNotification] = useState({ message: {}, isVisible: false });
  const [toastCallback, setToastCallback] = useState(null);
  const [messageCounter, setMessageCounter] = useState(0);
  const [hasUserBalance, setHasUserBalance] = useState(true);

  const navigate = useNavigate();
  const profileService = ProfileService();
  const myBalanceRef = useRef(0);
  const myLeveleRef = useRef(0);

  const getUserbalance = async () => {
    try {
      const response = await profileService.GetMyBalance();
      if (response && response.success) {
        myBalanceRef.current = response.femaleBalance 
          ? response.femaleBalance.purchase_points 
          : response.maleBalance.purchase_points;
      }
    } catch (error) {
      console.error("Failed to fetch balance", error);
    }
  };

  const getUserDetails = async () => {
    try {
      const response = await profileService.GetProfileDetails();
      if (response?.success) {
        myLeveleRef.current = response.success.rich_level;
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };


  const initialFunction = async () => {
    const userToken = localStorage.getItem("token");
    if (userToken) {
      await getUserbalance();
      await getUserDetails();
    }
    if (userToken && (myBalanceRef.current > 900 || myLeveleRef.current > 3)) return; 
    const offlineMessagesStored = JSON.parse(localStorage.getItem("OfflineMessagesList")) || [];
    let activeUserIndex = Number(localStorage.getItem("ActiveUserIndex")) || 0;
    if (offlineMessagesStored.length > 0) {
      if (activeUserIndex + 1 < offlineMessagesStored.length) {
        setTimeout(() => {
          processOfflineMessages();
        }, 8000);
      }
    }
    else {
      fetchOfflineMessages();
    }
  }

  useEffect(() => {
    initialFunction();
  }, []);

  const componentDidUpdate = async () => {
    await getUserbalance();
    const offlineMessagesStored = JSON.parse(localStorage.getItem("OfflineMessagesList")) || [];
    let activeUserIndex = Number(localStorage.getItem("ActiveUserIndex")) || 0;
    if (offlineMessagesStored.length > 0) {
      if (activeUserIndex + 1 < offlineMessagesStored.length) {
        setTimeout(() => {
          const fakeMessageInProgress = sessionStorage.getItem('fakeMessageInProgress');
          if(fakeMessageInProgress) return;
          processOfflineMessages();
        }, 8000);
      };
    }
    else {
      fetchOfflineMessages();
    }
  }

  useEffect(() => {
    if (hasUserBalance) return;
    componentDidUpdate();
  }, [hasUserBalance]);

  const fetchOfflineMessages = async () => {
    try {
      const response = await profileService.getOfflineMessages();
      if (response?.success) {
        localStorage.setItem("OfflineMessagesList", JSON.stringify(response.result));
        localStorage.setItem("ActiveUserIndex", 0);
        localStorage.setItem("ProcessedOfflineMessages", JSON.stringify([]));
        setTimeout(() => {
          const userToken = localStorage.getItem("token");
          if (userToken && (myBalanceRef.current > 900 || myLeveleRef.current > 3)) return;
          processOfflineMessages();
        }, 18000);
      }
    } catch (error) {
      console.error("Error fetching offline messages:", error);
    }
  };

  const navigateToChatScreen = (chatData) => {
    setToastNotification({ message: '', isVisible: false });
    setToastCallback(null);
    const route = sessionStorage.getItem('currentRouteName');
    let replace = false;
    if (route === 'recharge') {
      replace = true;
    }
    navigate('/chats', { state: chatData, replace: replace });
  };


  const processOfflineMessages = async () => {
    sessionStorage.setItem('fakeMessageInProgress',true);
    const userToken = localStorage.getItem("token");
    if (userToken) await getUserbalance();
    if (userToken && (myBalanceRef.current > 900 || myLeveleRef.current > 3)) return;
    let offlineMessages = JSON.parse(localStorage.getItem("OfflineMessagesList")) || [];
    let activeUserIndex = Number(localStorage.getItem("ActiveUserIndex")) || 0;

    if (activeUserIndex >= offlineMessages.length) {
      sessionStorage.removeItem('fakeMessageInProgress');
      return;
    }
    let currentUser = offlineMessages[activeUserIndex];
    let messageIndex = Number(localStorage.getItem(`MessageIndex_${currentUser.user_id}`)) || 0;

    const sendNextMessage = async () => {
      const userToken = localStorage.getItem("token");
      if (userToken) await getUserbalance();
      if (userToken && (myBalanceRef.current > 900 || myLeveleRef.current > 3)) return;
      if (messageIndex < currentUser.data.length) {
        let message = currentUser.data[messageIndex];
        message.time_stamp = Math.floor(Date.now() / 1000);
        let processedMessages = JSON.parse(localStorage.getItem("ProcessedOfflineMessages")) || [];
        let hostLocation = JSON.parse(localStorage.getItem("host_location")) || [];
        let locationName = (Array.isArray(hostLocation) &&
          hostLocation[activeUserIndex] &&
          hostLocation[activeUserIndex].name)
          ? hostLocation[activeUserIndex].name
          : 'Delhi';
        let userIndex = processedMessages.findIndex(user => user.user_id === currentUser.user_id);

        if (userIndex === -1) {
          processedMessages.push({
            user_id: currentUser.user_id,
            profile_id: currentUser.profile_id,
            name: currentUser.name,
            call_rate: currentUser.call_rate,
            profile_image: currentUser.profile_image,
            data: [message],
            time_stamp: Math.floor(Date.now() / 1000),
            location: locationName,
            type: "fake"
          });
        } else {
          processedMessages[userIndex].time_stamp = Math.floor(Date.now() / 1000);
          processedMessages[userIndex].data.push(message);
        }
        localStorage.setItem("ProcessedOfflineMessages", JSON.stringify(processedMessages));
        localStorage.setItem(`MessageIndex_${currentUser.user_id}`, messageIndex + 1);
        setMessageCounter(prev => prev + 1);

        const newMessageObject = {
          user_id: currentUser.user_id,
          profile_id: currentUser.profile_id,
          name: currentUser.name,
          call_rate: currentUser.call_rate,
          profile_image: currentUser.profile_image,
          data: message,
          time: Math.floor(Date.now() / 1000)
        };
        localStorage.setItem("host_profile_id", currentUser.profile_id);
        const route = sessionStorage.getItem('currentRouteName');
        if (!["login","fake-call","recharge"].includes(route)) {
          showToast(newMessageObject, () =>
            navigateToChatScreen({ profile_id: currentUser.profile_id, fakeChat: true })
          );
        }
        messageIndex++;
        setTimeout(() => {
          const userToken = localStorage.getItem("token");
          if (userToken && (myBalanceRef.current > 900 || myLeveleRef.current > 3)) return;
          sendNextMessage();
        }, 12000);
      } else {
        const userToken = localStorage.getItem("token");
        if (userToken && (myBalanceRef.current > 900 || myLeveleRef.current > 3)) return;
        initiateFakeCallForUser(currentUser, activeUserIndex, offlineMessages.length);
      }
    };

    sendNextMessage();
  };

  const initiateFakeCallForUser = (userDetails, currentUserIndex, totalUsers) => {
    let offlineMessages = JSON.parse(localStorage.getItem("ProcessedOfflineMessages")) || [];
    let currentIndex = Number(localStorage.getItem("ActiveUserIndex")) || 0;
    if (currentIndex < totalUsers && offlineMessages[currentIndex]?.data) {
      let videoURL = offlineMessages[currentIndex].data.find(msg => msg.fake_video === 1)?.video || "";
      const callId = localStorage.getItem("call_already_initiated");
      if (callId != userDetails.profile_id) {
        localStorage.setItem("call_already_initiated", userDetails.profile_id);
        const route = sessionStorage.getItem('currentRouteName');
        if (!["login","recharge"].includes(route)) {
          startFakeCall(userDetails.profile_id, userDetails.user_id, userDetails.profile_image, userDetails.name, videoURL, userDetails.call_rate);
        }
      }
    }
    if (currentIndex + 1 < totalUsers) {
      let interval;
      interval = setTimeout(() => {
        const userToken = localStorage.getItem("token");
        if (userToken && (myBalanceRef.current > 900 || myLeveleRef.current > 3)) {
          clearInterval(interval);
          return;
        }
        setMessageCounter(0);
        localStorage.setItem("ActiveUserIndex", currentIndex + 1)
        processOfflineMessages();
        clearInterval(interval);
      }, 40000);
    }
  };

  const startFakeCall = (profileId, user_id, profileImage, profileName, videoURL, callrate) => {
    const userToken = localStorage.getItem("token");
    const currentRoute = sessionStorage.getItem("currentRouteName") || null;
    if (userToken && (myBalanceRef.current > 900 || myLeveleRef.current > 3)) return;
    let replace = false;
    if (currentRoute === 'scratch-reward' || currentRoute === 'recharge') {
      replace = true;
    }
    navigate("/fake-call", {
      state: {
        profile_image: profileImage,
        profile_name: profileName,
        video_url: videoURL,
        profile_id: profileId,
        id: user_id,
        callRate: callrate,
        from: currentRoute ? `/${currentRoute}` : null,
      },
      replace: replace
    });
  };

  const showToast = (message, onClickCallback) => {
    setToastNotification({ message, isVisible: true });
    setToastCallback(() => onClickCallback);

    setTimeout(() => {
      setToastNotification({ message: '', isVisible: false });
      setToastCallback(null);
    }, 5000);
  };

  return (
    <ToastContext.Provider value={{ toastNotification, toastCallback, messageCounter, setHasUserBalance }}>
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);

//   const initialFunction = async () => {
//     const userToken = localStorage.getItem("token");
//     if (userToken) {
//       await getUserbalance();
//       await getUserDetails();
//     }
//     if (userToken && (myBalanceRef.current > 900 || myLeveleRef.current > 3)) return;
    
//     const offlineMessages = JSON.parse(localStorage.getItem("offlineMessagesList")) || [];
//     if (offlineMessages.length === 0) {
//       await fetchOfflineMessages();
//     } else {
//       processOfflineMessages();
//     }
//   };

//   useEffect(() => {
//     initialFunction();

//     return () => {
//       clearAllTimeouts();
//     };
//   }, []);

//   useEffect(() => {
//     if (hasUserBalance) return;
//     componentDidUpdate();
//   }, [hasUserBalance]);

//   const clearAllTimeouts = () => {
//     if (processingTimeoutRef.current) clearTimeout(processingTimeoutRef.current);
//     if (hostRotationTimeoutRef.current) clearTimeout(hostRotationTimeoutRef.current);
//     isProcessingRef.current = false;
//   };

//   const componentDidUpdate = async () => {
//     await getUserbalance();
//     await getUserDetails();
//     if (myBalanceRef.current > 900 || myLeveleRef.current > 3) return;
//     processOfflineMessages();
//   };

//   const fetchOfflineMessages = async () => {
//     try {
//       const response = await profileService.getOfflineMessages();
//       if (response?.success) {
//         localStorage.setItem("offlineMessagesList", JSON.stringify(response.result));
//         localStorage.setItem("activeUserIndex", 0);
//         localStorage.setItem("processedOfflineMessages", JSON.stringify([]));
//         setTimeout(() => processOfflineMessages(), 18000);
//       }
//     } catch (error) {
//       console.error("Error fetching offline messages:", error);
//     }
//   };

//   const processOfflineMessages = async () => {
//     if (isProcessingRef.current) return;
//     isProcessingRef.current = true;
//     clearAllTimeouts();

//     const userToken = localStorage.getItem("token");
//     if (userToken) await getUserbalance();
//     if (userToken && (myBalanceRef.current > 900 || myLeveleRef.current > 3)) {
//       isProcessingRef.current = false;
//       return;
//     }

//     const offlineMessages = JSON.parse(localStorage.getItem("offlineMessagesList")) || [];
//     let activeUserIndex = Number(localStorage.getItem("activeUserIndex")) || 0;

//     // Rotate to next host if current is completed
//     if (activeUserIndex >= offlineMessages.length) {
//       activeUserIndex = 0;
//       localStorage.setItem("activeUserIndex", activeUserIndex);
//     }

//     const currentUser = offlineMessages[activeUserIndex];
//     let messageIndex = Number(localStorage.getItem(`messageIndex_${currentUser.user_id}`)) || 0;

//     // If completed all messages for this host
//     if (messageIndex >= currentUser.data.length) {
//       moveToNextHost(activeUserIndex, offlineMessages.length);
//       return;
//     }

//     const sendNextMessage = async () => {
//       const message = currentUser.data[messageIndex];
//       message.time_stamp = Math.floor(Date.now() / 1000);

//       // Update processed messages
//       let processedMessages = JSON.parse(localStorage.getItem("processedOfflineMessages")) || [];
//       const userIndex = processedMessages.findIndex(u => u.user_id === currentUser.user_id);

//       if (userIndex === -1) {
//         processedMessages.push({
//           user_id: currentUser.user_id,
//           profile_id: currentUser.profile_id,
//           name: currentUser.name,
//           call_rate: currentUser.call_rate,
//           profile_image: currentUser.profile_image,
//           data: [message],
//           time_stamp: message.time_stamp,
//           type: "fake"
//         });
//       } else {
//         processedMessages[userIndex].data.push(message);
//       }

//       localStorage.setItem("processedOfflineMessages", JSON.stringify(processedMessages));
//       localStorage.setItem(`messageIndex_${currentUser.user_id}`, messageIndex + 1);
//       setMessageCounter(prev => prev + 1);

//       // Show toast notification
//       showToast({
//         user_id: currentUser.user_id,
//         profile_id: currentUser.profile_id,
//         name: currentUser.name,
//         call_rate: currentUser.call_rate,
//         profile_image: currentUser.profile_image,
//         data: message,
//         time: message.time_stamp
//       }, () => navigateToChatScreen({ profile_id: currentUser.profile_id, fakeChat: true }));

//       messageIndex++;

//       // Schedule next message or move to next host
//       if (messageIndex < currentUser.data.length) {
//         processingTimeoutRef.current = setTimeout(sendNextMessage, 12000);
//       } else {
//         initiateFakeCallForUser(currentUser, activeUserIndex, offlineMessages.length);
//       }
//     };

//     sendNextMessage();
//   };

//   const moveToNextHost = (currentIndex, totalHosts) => {
//     isProcessingRef.current = false;
//     const nextIndex = currentIndex + 1 < totalHosts ? currentIndex + 1 : 0;
//     localStorage.setItem("activeUserIndex", nextIndex);

//     hostRotationTimeoutRef.current = setTimeout(() => {
//       processOfflineMessages();
//     }, 30000);
//   };

//   const initiateFakeCallForUser = (userDetails, currentIndex, totalHosts) => {
//     const offlineMessages = JSON.parse(localStorage.getItem("processedOfflineMessages")) || [];
//     const videoURL = offlineMessages[currentIndex]?.data.find(msg => msg.fake_video === 1)?.video || "";
    
//     if (localStorage.getItem("call_already_initiated") !== userDetails.profile_id) {
//       localStorage.setItem("call_already_initiated", userDetails.profile_id);
//       startFakeCall(
//         userDetails.profile_id,
//         userDetails.user_id,
//         userDetails.profile_image,
//         userDetails.name,
//         videoURL,
//         userDetails.call_rate
//       );
//     }

//     moveToNextHost(currentIndex, totalHosts);
//   };

//   const startFakeCall = (profileId, userId, profileImage, profileName, videoURL, callRate) => {
//     if (myBalanceRef.current > 900 || myLeveleRef.current > 3) return;
    
//     navigate("/fake-call", {
//       state: {
//         profile_image: profileImage,
//         profile_name: profileName,
//         video_url: videoURL,
//         profile_id: profileId,
//         id: userId,
//         callRate: callRate,
//         from: sessionStorage.getItem('currentRouteName') || null,
//       },
//       replace: ['scratch-reward', 'recharge'].includes(sessionStorage.getItem('currentRouteName'))
//     });
//   };

//   const navigateToChatScreen = (chatData) => {
//     setToastNotification({ message: {}, isVisible: false });
//     setToastCallback(null);
//     navigate('/chats', { 
//       state: chatData, 
//       replace: sessionStorage.getItem('currentRouteName') === 'recharge' 
//     });
//   };

//   const showToast = (message, onClickCallback) => {
//     setToastNotification({ message, isVisible: true });
//     setToastCallback(() => onClickCallback);
//     setTimeout(() => {
//       setToastNotification({ message: {}, isVisible: false });
//       setToastCallback(null);
//     }, 5000);
//   };

//   return (
//     <ToastContext.Provider value={{ toastNotification, toastCallback, messageCounter, setHasUserBalance }}>
//       {children}
//     </ToastContext.Provider>
//   );
// };

// export const useToast = () => useContext(ToastContext);

