import React, { useState } from 'react'
import './bottomTabs.css'
import { useNavigate } from 'react-router-dom'

function BottomTabs({currentTab}) {
    const navigate = useNavigate();
    const goToScreen  = (route) => {
        navigate(route);
    };

    return (
        <div className="bottom-nav">
            <div className='bottom-icons-outer'>
                <div onClick={() => goToScreen ('/home')} className='btn-icons'>
                    <img 
                    className="btm-icon-image" 
                    src={require(`../../assets/${currentTab==='home' ? 'Discover_fill.webp' : 'Discover_unfill.webp'}`)}
                    alt="home" />
                </div>
            </div>
            <div className='bottom-icons-outer'>
                <div onClick={() => goToScreen ('/messages')} className='btn-icons'>
                    <img 
                    className="btm-icon-image" 
                    src={require(`../../assets/${currentTab === 'messages' ? 'Chat_fill.webp' : 'Chat_infill.webp'}`)}
                    alt="chat" />
                </div>
            </div>
            <div className='bottom-icons-outer'>
                <div onClick={() => goToScreen ('/profile')} className='btn-icons'>
                    <img 
                    className="btm-icon-image"
                    src={require(`../../assets/${currentTab === 'profile' ? 'profile_fill.webp' : 'Profile_unfill.webp'}`)}
                    alt="profile" 
                    />
                </div>
            </div>
        </div>
    )
}

export default BottomTabs