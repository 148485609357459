import React, { useEffect, useState } from "react";
import PrivateRoute from "./routes/PrivateRoute";
import "./style.css";
import "./App.css";
import { AppProvider } from "./contexts/AppProvider";
import ReactGA from "react-ga4";
import MainScreen from "./entrypoint/MainScreen";
import useViewportHeight from "./components/viewPortHeight/viewportheight";
import { useLocation } from "react-router-dom";
import RotateScreen from "./components/rotateScreen/RotateScreen";
import PwaInstallationPopup from "./components/pwaInstallationPopup/PwaInstallationPopup";
import { PwaInstallationIosPopup } from "./components/pwaInstallationIosPopup/PwaInstallationIosPopup";
import PwaStatus from "./constant/PwaStatus";

ReactGA.initialize(process.env.REACT_APP_Measurement_ID);

function App() {
  useViewportHeight();
  const location = useLocation();
  const dummyUserImage = 'https://starmate2025.blr1.cdn.digitaloceanspaces.com/ringliveProfileImages/10.jpeg';

  const [pwaInstallStatus, setPwaInstallStatus] = useState(PwaStatus.Install);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showPwaInstallationPopup,setShowPwaInstallationPopup] =
    useState(false);
  const [showPwaInstallationPopupForIos, setShowPwaInstallationPopupForIos] =
    useState(false);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
          console.log(
            "Service Worker registered with scope:",
            registration.scope
          );
        })
        .catch((error) => {
          console.log("Service Worker registration failed:", error);
        });
    }
  }, []);

  const [orientation, setOrientation] = useState(
    window.innerWidth > window.innerHeight ? "landscape" : "portrait"
  );
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Enhanced Mobile Detection: User Agent + Screen Dimensions
    const detectMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const isMobileDevice =
        /android|iphone|ipad|ipod|blackberry|windows phone|opera mini|iemobile|mobile/i.test(
          userAgent
        );
      const isSmallScreen = window.innerWidth <= 768;
      setIsMobile(isMobileDevice || isSmallScreen);
    };

    detectMobile();

    const handleResize = () => {
      const newOrientation =
        window.innerWidth > window.innerHeight ? "landscape" : "portrait";
      setOrientation(newOrientation);
      detectMobile();
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  useEffect(() => {
    const currentPath = location.pathname;
    const currentRouteName = currentPath.split('/').pop();
    const previousRoute = sessionStorage.getItem('currentRouteName');
    sessionStorage.setItem('currentRouteName', currentRouteName);
  }, [location]);

  useEffect(() => {
    const hostLocation = localStorage.getItem("host_location");
    if (!hostLocation) {
      fetchNearbyCities();
    }
    const userImage = localStorage.getItem("my_profile_image");
    if (userImage) return;
    localStorage.setItem("my_profile_image", dummyUserImage)
  }, []);

  const fetchNearbyCities = async () => {
    if (!navigator.geolocation) {
      console.error("Geolocation is not supported by your browser.");
      return;
    }
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        try {
          const response = await fetch(
            `https://overpass-api.de/api/interpreter?data=[out:json];node(around:100000,${latitude},${longitude})[place=city];out;`
          );
          if (!response.ok) {
            console.error("Failed to fetch nearby cities.");
            return;
          }
          const data = await response.json();
          const extractedCities = data.elements
            .filter((el) => el.tags && el.tags.name)
            .map((el) => ({
              name: el.tags.name,
              latitude: el.lat,
              longitude: el.lon,
            }));
          localStorage.setItem('host_location', JSON.stringify(extractedCities));
        } catch (error) {
          console.error(error.message);
        }
      },
      () => {
        const extractedCities = [
          { name: "New Delhi", latitude: 28.6138954, longitude: 77.2090057 },
          { name: "Bahadurgarh", latitude: 28.6933239, longitude: 76.9332373 },
          { name: "Sonipat", latitude: 28.9953758, longitude: 77.0233627 },
          { name: "Noida", latitude: 28.5706333, longitude: 77.3272147 },
          { name: "Rohtak", latitude: 28.9010899, longitude: 76.5801935 },
          { name: "Manesar", latitude: 28.3617283, longitude: 76.9402153 },
          { name: "Bhiwadi", latitude: 28.2039413, longitude: 76.837441 },
          { name: "Ghaziabad", latitude: 28.6711527, longitude: 77.4120356 },
          { name: "Delhi", latitude: 28.6517178, longitude: 77.2219388 },
          { name: "Gurugram", latitude: 28.4646148, longitude: 77.0299194 },
          { name: "Faridabad", latitude: 28.4031478, longitude: 77.3105561 }
        ];
        localStorage.setItem('host_location', JSON.stringify(extractedCities));
      }
    );
  };

  const checkStandaloneMode = () => {
    return (
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone === true
    );
  };

  const isIosDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return (
      userAgent.includes("iphone") ||
      userAgent.includes("ipad") ||
      userAgent.includes("ipod")
    );
  };

  const checkIosStandaloneMode = () => {
    return isIosDevice() && window.navigator.standalone === true;
  };

  const closeIosConfirmationPopup = () => {
    setShowPwaInstallationPopupForIos(false);
  };

  const openPwaApp = () => {
    closePwaConfirmationPopup();
    window.open(window.location.origin, "_blank");
  };

  const handleBeforeInstallPrompt = (event) => {
    const isStandalone = checkStandaloneMode();
    if (!isStandalone) {
      // const alreadyOpen = sessionStorage.getItem(
      //   "already_open_pwa_confirmation_popup"
      // );
      // if (!alreadyOpen) {
        event.preventDefault();
        setDeferredPrompt(event);
        setPwaInstallStatus(PwaStatus.Install);
        setShowPwaInstallationPopup(true);
        // sessionStorage.setItem("already_open_pwa_confirmation_popup", "true");
      // }
    } 
  };

  const handleIosPwaCheck = () => {
    const isIos = isIosDevice();
    const isStandalone = checkIosStandaloneMode();
    if (isIos && !isStandalone) {
      const alreadyOpenIos = sessionStorage.getItem(
        "already_open_pwa_confirmation_popup_for_ios"
      );
      if (!alreadyOpenIos) {
        setShowPwaInstallationPopupForIos(true);
        sessionStorage.setItem(
          "already_open_pwa_confirmation_popup_for_ios",
          "true"
        );
      }
    }
  };

  const installPwaApplication = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          setPwaInstallStatus(PwaStatus.Installing);
        } else {
          closePwaConfirmationPopup();
        }
      });
    }
  };

  const closePwaConfirmationPopup = () => {
    setDeferredPrompt(null);
    setShowPwaInstallationPopup(false);
  };

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    window.addEventListener("appinstalled", () => {
      setTimeout(() => {
        setPwaInstallStatus(PwaStatus.Installed);
      }, 10000);
    });

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
      window.removeEventListener("appinstalled", () =>
        setPwaInstallStatus(PwaStatus.Installed)
      );
    };
  }, []);

  useEffect(() => {
    handleIosPwaCheck();
  }, []);

  return (
    <>
      {isMobile && orientation === "landscape" && (
        <RotateScreen />
      )}
      <AppProvider>
        <MainScreen />
      </AppProvider>

      {showPwaInstallationPopup && (
        <PwaInstallationPopup
          pwaInstallStatus={pwaInstallStatus}
          handleInstall={installPwaApplication}
          onClose={closePwaConfirmationPopup}
          openApp={openPwaApp}
        />
      )}
      {showPwaInstallationPopupForIos && (
        <PwaInstallationIosPopup
          closeIosConfirmationPopup={closeIosConfirmationPopup}
        />
      )}
    </>
  );
}

export default App;
