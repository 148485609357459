import React from 'react';
import './FakeCallPopup.css';
import daimondIcon from '../../assets/daimondnew.png'

function FakeCallPopup({ amount, points, payNow }) {
    return (
        <div className="fakecall-popup-overlay">
            <div className="fakecall-popup-box">
                <div className='fakecall-popup-text-outer'>
                    <p className='fakecall-popup-text-top'>ओह नहीं! फ्री कॉल्स खत्म हो गया</p>
                    <p className='fakecall-popup-text'>कॉल जारी रखने के लिए अभी रीचार्ज करें।</p>
                </div>

                <div className='fakecall-popup-amt-details'>
                    <div className='diamond-outer'>
                        <div className="diamond-icon-outer">
                            <img src={daimondIcon} />
                        </div>
                        <p>{points}</p>
                    </div>
                    <div className="amount-outer">
                        <p>₹{amount}</p>
                    </div>
                </div>
                <div className="fakecall-button-group">
                    <div className="fakecall-confirm-button" onClick={payNow}>
                        <p>Recharge Now</p>
                    </div>
                </div>
            </div>
        </div>
        // <div className="fakecall-popup-overlay">
        //     <div className="fakecall-popup-box">
        //         <div className='fakecall-popup-text-outer'>
        //             <p className='fakecall-popup-text'>Oh no! Balance lost!</p>
        //         </div>
        //         <div className='fake-call-message-outer'>
        //             <p>Recharge now to continue your call</p>
        //         </div>
        //         <div className='fakecall-popup-amt-details'>
        //             <p className='rupee-text'>₹{amount}</p>
        //             <div className='diamond-text'>
        //                 <p >Diamonds : {points}</p>
        //             </div>
        //         </div>
        //         <div className="fakecall-button-group">
        //             <div className="fakecall-confirm-button" onClick={payNow}>
        //                 <p>Pay Now</p>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
}

export default FakeCallPopup;