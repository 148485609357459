import React, {
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
const StoreUiContext = createContext();

export const StoreUiProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentFakePage, setCurrentFakePage] = useState(1);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [fakeNextPageUrl, setFakeNextPageUrl] = useState(null);
  const [hasLiveHosts, setHasLiveHosts] = useState(false);
  const [liveHostList, setLiveHostList] = useState([]);
  const [hostNextPageUrl, setHostNextPageUrl] = useState(null);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [finalChatList, setFinalChatList] = useState([]);
  const [hasFinalChatList, setHasFinalChatList] = useState(false);
  const [recentChatList, setRecentChatList] = useState([]);
  const [hasRecentChatList, setHasRecentChatList] = useState(false);

  const contextValue = useMemo(
    () => ({
      finalChatList,
      setFinalChatList,
      setCurrentPage,
      currentPage,
      currentFakePage,
      setCurrentFakePage,
      setFakeNextPageUrl,
      fakeNextPageUrl,
      setNextPageUrl,
      nextPageUrl,
      liveHostList,
      setLiveHostList,
      hasLiveHosts,
      setHasLiveHosts,
      hostNextPageUrl,
      setHostNextPageUrl,
      currentPageIndex,
      setCurrentPageIndex,
      hasFinalChatList,
      setHasFinalChatList,
      recentChatList,
      setRecentChatList,
      hasRecentChatList,
      setHasRecentChatList
    }),
    [
      recentChatList,
      hasRecentChatList,
      hasFinalChatList,
      finalChatList,
      currentPage,
      fakeNextPageUrl,
      currentFakePage,
      nextPageUrl,
      hasLiveHosts,
      liveHostList,
      hostNextPageUrl,
      currentPageIndex
    ]
  );

  return (
    <StoreUiContext.Provider value={contextValue}>
      {children}
    </StoreUiContext.Provider>
  );
};

export const useStoreUiContext = () => {
  return useContext(StoreUiContext);
};