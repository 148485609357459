import React from 'react';
import { CountryProvider } from './CountryContext';
import { RoutesHandingProvider } from './RoutesHandingContex';
import { ScrollHandingProvider } from './ScrollHandingContex';
import { ToastProvider } from './ToastContext';
import { StoreUiProvider } from './StoreUiContext';
// import { UserProvider } from './UserContext';

export const AppProvider = ({ children }) => (
  <StoreUiProvider>
    <ToastProvider>
      <CountryProvider>
        <RoutesHandingProvider>
          <ScrollHandingProvider>
            {children}
          </ScrollHandingProvider>
        </RoutesHandingProvider>
      </CountryProvider>
    </ToastProvider>
  </StoreUiProvider>
);
