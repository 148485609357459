import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import audioRingtone from "../../../assets/audio-ringing.mp3";
import callingAnimation from "../../../assets/circle_fade.json";
import callCut from "../../../assets/Disconnect.svg";
import cameraFlip from "../../../assets/camera-flip.svg";
import callPick from "../../../assets/call-answer.svg";
import cameraOfIcon from "../../../assets/camOff.svg";
import soundOfIcon from "../../../assets/soundOff.svg";
import micOfIcon from "../../../assets/micOn.svg";
import cameraOnIcon from "../../../assets/Cam On.svg";
import soundOnIcon from "../../../assets/Sound On.svg";
import micOnIcon from "../../../assets/Mic On.svg";
import callCutIcon from "../../../assets/call_end.svg";
import swipeUpSVg from "../../../assets/swipe-up.svg";
import "./FakeCallPage.css";
import { ProfileService } from "../../../services/ProfileService";
import Draggable from "react-draggable";
import FakeCallPopup from "../../../components/fakeCallPopup/FakeCallPopup";
import PlanType from "../../../constant/PlanType";
import Lottie from "lottie-react";


const FakeCallPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const profileService = ProfileService();
  const audioRingRef = useRef(null);
  const userVideoRef = useRef(null);
  const streamRef = useRef(null);
  const [visibleCallScreen, setVisibleCallScreen] = useState(true);
  const [isCallPicked, setIsCallPicked] = useState(false);
  const { profile_image, profile_name, video_url, from, profile_id, id, callRate } = location.state || {};
  const [isCameraOn, setIsCameraOn] = useState(true);
  const [isFrontCamera, setIsFrontCamera] = useState(true);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [stopTimer, setStopTimer] = useState(false);
  const [paymentPopup, setPaymentPopup] = useState(false);
  const [amount, setAmount] = useState(null);
  const [points, setPoints] = useState(null);
  const [planId, setPlanId] = useState(null);
  const [swipePosition, setSwipePosition] = useState(0);
  const [callDropPosition, setCallDropPosition] = useState(0);
  const [startY, setStartY] = useState(null);
  const videoRef = useRef(null);
  const callTimeoutRef = useRef(null);
  const isCallReceivedRef = useRef(false);
  const isCleanupExecuteRef = useRef(false);
  const timerRef = useRef(null);
  const fakeVideoUrl = 'https://vid.duome.live/call/eve/2024-08-16/42080695/3717f067b471d2b28e1fcef9ff4ef619.mp4';

  useEffect(() => {
    const callScreenAlreadyLoad = localStorage.getItem(
      "fake_call_screen_already_load"
    );
    if (!from) window.history.back();
    if (!callScreenAlreadyLoad) {
      timerRef.current = setTimeout(() => {
        if (audioRingRef.current) {
          audioRingRef.current.play();
        }
      }, 500);
      getPlanListWeb();
      localStorage.setItem("fake_call_screen_already_load", true);
      startCallDialCountdown();
      startStream();
    } else {
      navigateToPreviousPage();
    }
    return () => {
      localStorage.removeItem("fake_call_screen_already_load");
      if (audioRingRef.current) {
        audioRingRef.current.pause();
        audioRingRef.current.currentTime = 0;
      }
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      if (callTimeoutRef.current) clearTimeout(callTimeoutRef.current);
      stopMediaStream();
      if (!isCleanupExecuteRef.current) {
        endCall();
      }
    };
  }, []);

  useEffect(() => {
    if (isCallPicked && !stopTimer) {
      playLocalStream();
      const interval = setInterval(() => {
        setElapsedTime(prevTime => prevTime + 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [isCallPicked, stopTimer]);

  const startCallDialCountdown = () => {
    callTimeoutRef.current = setTimeout(() => {
      if (!isCallReceivedRef.current) {
        navigateToPreviousPage();
      }
    }, 15000);
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const navigateToPreviousPage = () => {
    isCleanupExecuteRef.current = true;
    window.history.back();
  };


  const startStream = async () => {
    try {
      if (!streamRef.current) {
        const constraints = {
          video: { facingMode: "user" },
          audio: true,
        };
        const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
        streamRef.current = mediaStream;
      }
    } catch (error) {
      console.error("Error accessing media devices:", error);
    }
  };

  const playLocalStream = () => {
    if (userVideoRef.current) {
      userVideoRef.current.srcObject = streamRef.current;
    }
  };

  const handlePickup = () => {
    isCallReceivedRef.current = true;
    setVisibleCallScreen(false);
    setIsCallPicked(true);
    if (audioRingRef.current) {
      audioRingRef.current.pause();
      audioRingRef.current.currentTime = 0;
    }
  };

  const handleDrop = () => {
    endCall();
  };

  const endCall = () => {
    setVisibleCallScreen(false);
    setIsCallPicked(false);
    navigateToPreviousPage();
  };

  const endVideoCall = () => {
    stopMediaStream();
    setStopTimer(true);
    setPaymentPopup(true);
  };

  const stopMediaStream = async () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }
  };

  const toggleCamera = () => {
    if (streamRef.current) {
      const videoTrack = streamRef.current.getVideoTracks()[0];
      if (videoTrack) {
        videoTrack.enabled = !videoTrack.enabled;
        setIsCameraOn(videoTrack.enabled);
      }
    }
  };

  const getPlanListWeb = async () => {
    try {
      const response = await profileService.getPlanlistWeb();
      if (response.success) {
        const planList = response.result.extra_data;
        for (let i = 0; i < planList.length; i++) {
          if (PlanType.video_call_plan === planList[i].name) {
            setAmount(planList[i].amount);
            setPlanId(planList[i].id);
            setPoints(planList[i].points);
            break;
          }
        }
      }
      else {
        console.error(response.error);
      }
    } catch (error) {
      console.error("Error fetching plan list:", error);
    }
  };

  const payNow = async () => {
    isCleanupExecuteRef.current = true;
    const token = localStorage.getItem('token');
    navigate('/recharge', {
      state: {
        points: points,
        amount: amount,
        plan_id: planId,
        profile_id: profile_id,
        fake_call_type: true,
        profileImage: profile_image,
        id: id,
        name: profile_name,
        callRate: callRate
      },
      replace: true
    });
  };

  const dropCallRef = useRef(null);
  const pickCallRef = useRef(null);

  const handleTouchStart = (e) => {
    setStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e, setPosition, ref) => {
    if (startY !== null && ref.current) {
      const currentY = e.touches[0].clientY;
      const distance = currentY - startY;

      const parentHeight = ref.current.offsetHeight;

      if (distance < 0 && Math.abs(distance) <= parentHeight - 50) {
        setPosition(distance);
      }
    }
  };

  const handleTouchEnd = (position, threshold, callback, resetPosition) => {
    if (position < threshold) {
      callback();
    }
    resetPosition(0);
  };

  return (
    <>
      {visibleCallScreen && (
        <>
          <audio ref={audioRingRef} src={audioRingtone} loop />
          <div className="fake-call-wrapper">
            <div className="fake-videoCall-page-inner"
              style={{ backgroundImage: `url(${profile_image})` }}></div>
            <div className="fake-videoCall-top-outer">
              <div className="host-image-outer">
                <img src={profile_image} alt="profile" />
              </div>
              <div className="host-details-outer">
                <h1>{profile_name}</h1>
                <p>Incoming video call…</p>
              </div>
            </div>
            <Lottie
              animationData={callingAnimation}
              className="host-lottie-animation"
            />
            <div className="fake-videoCall-bottom-outer">
              <div className="call-btn-outer" ref={dropCallRef}>
                <div className="call-cut-inner"
                  onClick={handleDrop}
                  onTouchStart={handleTouchStart}
                  onTouchMove={(e) => handleTouchMove(e, setCallDropPosition, dropCallRef)}
                  onTouchEnd={() =>
                    handleTouchEnd(callDropPosition, -20, handleDrop, setCallDropPosition)}
                  style={{ transform: `translateY(${callDropPosition}px)` }}
                ></div>
                <p>Decline</p>
              </div>
              <div className="call-btn-outer" ref={pickCallRef} >
                <div className="call-pick-inner"
                  onClick={handlePickup}
                  onTouchStart={handleTouchStart}
                  onTouchMove={(e) => handleTouchMove(e, setSwipePosition, pickCallRef)}
                  onTouchEnd={() =>
                    handleTouchEnd(swipePosition, -20, handlePickup, setSwipePosition)
                  }
                  style={{ transform: `translateY(${swipePosition}px)` }}></div>
                <p>Accept</p>
              </div>
            </div>
          </div>
          {/* <div className="fake-call-wrapper">
            <div
              className="fake-call-BGimage-wrapper"
              style={{ backgroundImage: `url(${profile_image})` }}
            >
              <div className="fake-call-top-outer">
                <p>{profile_name}</p>
                <div
                  className="fake-call-image-outer"
                  style={{ backgroundImage: `url(${profile_image})` }}
                ></div>
              </div>
              <div className="fake-call-bottom-outer">
                <div className="call-btn-outer" ref={dropCallRef}>
                  <div className="swiper-image-outer">
                    <img src={swipeUpSVg} />
                  </div>
                  <div className="call-icon-outer"
                    onClick={handleDrop}
                    onTouchStart={handleTouchStart}
                    onTouchMove={(e) => handleTouchMove(e, setCallDropPosition, dropCallRef)}
                    onTouchEnd={() =>
                      handleTouchEnd(callDropPosition, -20, handleDrop, setCallDropPosition)}
                    style={{ transform: `translateY(${callDropPosition}px)` }}
                  >
                    <img src={callCut} />
                  </div>
                  <p>Decline</p>
                </div>
                <div className="call-btn-outer" ref={pickCallRef}>
                  <div className="swiper-image-outer">
                    <img src={swipeUpSVg} />
                  </div>
                  <div className="call-icon-outer"
                    onClick={handlePickup}
                    onTouchStart={handleTouchStart}
                    onTouchMove={(e) => handleTouchMove(e, setSwipePosition, pickCallRef)}
                    onTouchEnd={() =>
                      handleTouchEnd(swipePosition, -20, handlePickup, setSwipePosition)
                    }
                    style={{ transform: `translateY(${swipePosition}px)` }}
                  >
                    <img src={callPick} />
                  </div>
                  <p>Swipe to accept</p>
                </div>
              </div>
            </div>
          </div> */}
        </>
      )}
      {isCallPicked && (
        <div className="fake-call-wrapper">
          <div className="fake-call-outer">
            <video ref={videoRef} autoPlay playsInline onEnded={endVideoCall}>
              <source src={video_url || fakeVideoUrl} type="video/mp4" />
            </video>
          </div>
          <Draggable bounds="parent">
            <div className="fake-call-user-view">
              <video ref={userVideoRef} autoPlay muted playsInline />
            </div>
          </Draggable>

          <div className="fake-caller-details-outer">
            <div className="fake-caller-profile-outer">
              <img src={profile_image} />
            </div>
            <div className="fake-caller-host-name-outer">
              <div className="fake-caller-host-name-inner">
                <p className="fake-caller-host-inner-text">{profile_name}</p>
                <p className="fake-caller-inner-call-duration">{formatTime(elapsedTime)}</p>
              </div>
            </div>
          </div>
          <div className="user-options-outer">
            <div className="video-icon-outer" onClick={toggleCamera}>
              {isCameraOn ? (
                  <img src={cameraOnIcon} alt="cam-on" />
                ) : (
                  <img src={cameraOfIcon} alt="cam-of" />
                )}
            </div>
            <div className="call-cut-icon-outer" onClick={handleDrop}>
              <img src={callCutIcon}  alt="CallCut-icon"/>
            </div>
          </div>

          {/* <div className="fake-caller-details-outer">
            <p>{profile_name}</p>
            <span>{formatTime(elapsedTime)}</span>
          </div>
          <div className="camera-switch-drop-outer">
            <div className="camera-switch-outer" onClick={switchCamera}>
              <img src={cameraFlip} alt="call-hang" />
            </div>
          </div>
          <div className="user-options-outer">
            <div className="call-connected-icons-outer" onClick={toggleCamera}>
              {isCameraOn ? (
                <img src={cameraOnIcon} alt="cam-on" />
              ) : (
                <img src={cameraOfIcon} alt="cam-of" />
              )}
            </div>
            <div className="call-connected-icons-outer" onClick={toggleAudio}>
              {isAudioOn ? (
                <img src={soundOnIcon} alt="sound-on" />
              ) : (
                <img src={soundOfIcon} alt="sound-of" />
              )}
            </div>
            <div className="call-connected-icons-outer" onClick={toggleMic}>
              {isMicOn ? (
                <img src={micOnIcon} alt="Mic-on" />
              ) : (
                <img src={micOfIcon} alt="Mic-off" />
              )}
            </div>
            <div className="call-connected-icons-outer" onClick={handleDrop}>
              <img src={callCutIcon} alt="CallCut-icon" />
            </div>
          </div> */}
        </div>
      )}

      {paymentPopup && <FakeCallPopup amount={amount} points={points} payNow={payNow} />}
    </>
  );
};

export default FakeCallPage;
