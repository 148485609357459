import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Loader from "../components/Loader/Loader";
import ProtectedRoute from '../routes/ProtectedRoute';
import PublicRoute from "./PublicRoute";
import { GoogleOAuthProvider } from "@react-oauth/google";
import FakeCallPage from "../pages/modules/fakeCallPage/FakeCallPage";
import PwaInstallationPopup from "../components/pwaInstallationPopup/PwaInstallationPopup";

const Home = lazy(() => import("../pages/home/Home"));
const Login = lazy(() => import("../pages/auth/Login"));
const MobileLogin = lazy(() => import("../pages/auth/MobileLogin"));
const Registration = lazy(() => import("../pages/auth/Registration"));
const CountryCode = lazy(() => import("../pages/auth/CountryCode"));
const CallScreen = lazy(() => import("../pages/callScreen/CallScreen"));
const MessageScreen = lazy(() => import("../pages/modules/Messages/messages"));
const Profile = lazy(() => import("../pages/modules/Profile/profile"));
const ChatScreen = lazy(() => import("../components/chatScreen/ChatScreen"));
const BalanceScreen = lazy(() => import("../pages/modules/MyBalance/myBalance"));
const RechargeScreen = lazy(() => import("../pages/modules/Recharge/recharge"));
const MyProfile = lazy(() => import("../pages/modules/MyProfile/myProfile"));
const SettingScreen = lazy(() => import("../pages/modules/Setting/setting"));
const TransactionsScreen = lazy(() => import("../pages/modules/Transactions/transactions"));
const TestScreen = lazy(() => import("../pages/modules/Test/test"));
const ProfileDetails = lazy(() => import("../pages/modules/ProfileDetails/profileDetails"));
const UploadImage = lazy(() => import("../components/uploadImage-popup/uploadImage"));
const WelcomeScreen = lazy(() => import("../pages/welcome/welcome"));
const VideoScreen = lazy(() => import("../pages/videoCallandChat/VideoScreen"));
const LiveBroadcast = lazy(() => import("../pages/liveBroadcast/liveBroadcast"));
export default function PrivateRoute() {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<Home />}  />
        <Route path="/home" element={<Home />} >
        </Route>
        <Route
          path="/login"
          element={
            <PublicRoute
              element={() => (
                <GoogleOAuthProvider 
                 clientId="720604246001-412ck736ombalqc9av97h3ndvf96oanh.apps.googleusercontent.com"
                //  clientId="391256528894-kgvrjip89ip02g4shja00s26nhmni47b.apps.googleusercontent.com"
                > 
                  <Login />
                </GoogleOAuthProvider>
              )}
            />
          }
        />
       
        <Route path="/mobilelogin" element={<PublicRoute element={MobileLogin} />} />
        <Route path="/registration" element={<PublicRoute element={Registration} />} />
        <Route path="/country" element={<PublicRoute element={CountryCode} />} />
        <Route path="/calling" element={<ProtectedRoute element={CallScreen} />} />
        <Route path="/video" element={<ProtectedRoute element={VideoScreen} />} />
        <Route path="/messages" element={<MessageScreen/>} />
        <Route path="/profile" element={<ProtectedRoute element={Profile} />} />
        <Route path="/chats" element={<ChatScreen/>} />
        <Route path="/myBalance" element={<ProtectedRoute element={BalanceScreen} />} />
        <Route path="/recharge" element={<ProtectedRoute element={RechargeScreen} />} />
        <Route path="/myProfile" element={<ProtectedRoute element={MyProfile} />} />
        <Route path="/setting" element={<ProtectedRoute element={SettingScreen} />} />
        <Route path="/transactions" element={<ProtectedRoute element={TransactionsScreen} />} />
        <Route path="/test" element={<BalanceScreen />} />
        <Route path="/profileDetails" element={<ProtectedRoute element={ProfileDetails} />} />
        <Route path="/uploadImage" element={<ProtectedRoute element={UploadImage} />} />
        <Route path="/install" element={<WelcomeScreen />} />
        <Route path="/broad" element={ <ProtectedRoute element={LiveBroadcast}/>} />
        <Route path="/fake-call" element={<FakeCallPage/>} />
      </Routes>
    </Suspense>
  );
}
