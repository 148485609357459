import { useNavigate } from "react-router-dom";
import { Get, Post, PostForUpdateProfile } from "../utils/ApiUtility";
 
export const ProfileService = () => {
  const navigate = useNavigate();
 
  const generateTokenZegowithoutroom = async () => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      appid: "21",
    };
    return await Get("generateTokenZegowithoutroom", headers);
  };
  const GetProfileDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/login");
        return;
      }
      const headers = {
        Authorization: `Bearer ${token}`,
        appid: "21",
      };
 
      const response = await Post("details", {}, headers);
 
      if (response.success != null) {
        return response;
      } else {
        throw new Error(response.error.message);
      }
    } catch (error) {
      if (error.message === "Session expired. Please log in again.") {
        return { error: "Session expired. Please log in again." };
      } else {
        return { error: error.message || "An unexpected error occurred." };
      }
    }
  };
 
  const GetMyBalance = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/login");
        return;
      }
      const headers = {
        Authorization: `Bearer ${token}`,
        appid: "21",
      };
      const response = await Get("points", headers);
      if (response.success) {
        return response;
      } else {
        throw new Error(response.error.message);
      }
    } catch (error) {
      if (error.message === "Session expired. Please log in again.") {
        return { error: "Session expired. Please log in again." };
      } else {
        return { error: error.message || "An unexpected error occurred." };
      }
    }
  };
 
  const GetPlanlist = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/login");
        return;
      }
      const headers = {
        Authorization: `Bearer ${token}`,
        appid: "21",
      };
      const response = await Get("planlist", headers);
      if (response.success) {
        return response;
      } else {
        throw new Error(response.error.message);
      }
    } catch (error) {
      if (error.message === "Session expired. Please log in again.") {
        return { error: "Session expired. Please log in again." };
      } else {
        return { error: error.message || "An unexpected error occurred." };
      }
    }
  };
 
  const UpdateProfile = async (model, is_pic = false) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/login");
        return;
      }
      const headers = {
        Authorization: `Bearer ${token}`,
        "accept-encoding": "gzip",
        appid: "21",
        "cache-control": "no-cache",
        connection: "Keep-Alive",
        host: "zeep.live",
        "user-agent": "okhttp/4.9.1",
        accept: "application/json",
      };
 
      const response = await PostForUpdateProfile(
        "update-profile-new-review",
        model,
        headers,
        is_pic
      );
 
      if (response.success) {
        return response;
      } else {
        throw new Error(response.error.message || "Failed to update profile.");
      }
    } catch (error) {
      if (error.message === "Session expired. Please log in again.") {
        return { error: "Session expired. Please log in again." };
      }
      return { error: error.message || "An unexpected error occurred." };
    }
  };
 
  const GetHostProfileDetails = async (Id) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/login");
        return;
      }
      const url = `getprofiledata?id=${Id}`;
      const headers = {
        Authorization: `Bearer ${token}`,
        appid: "21",
      };
 
      const response = await Get(url, headers);
      if (response.success) {
        return response;
      } else {
        throw new Error(response.error.message);
      }
    } catch (error) {
      if (error.message === "Session expired. Please log in again.") {
        return { error: "Session expired. Please log in again." };
      } else {
        return { error: error.message || "An unexpected error occurred." };
      }
    }
  };
 
  const FollowHost = async (Id) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/login");
        return;
      }
      const url = `follow?following_id=${Id}`;
      const headers = {
        Authorization: `Bearer ${token}`,
        appid: "21",
      };
      const response = await Post(url, {}, headers);
      if (response.success) {
        return response;
      } else {
        throw new Error(response.error.message);
      }
    } catch (error) {
      if (error.message === "Session expired. Please log in again.") {
        return { error: "Session expired. Please log in again." };
      } else {
        return { error: error.message || "An unexpected error occurred." };
      }
    }
  };
 
  const GetFollowCount = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/login");
        return;
      }
      const url = `getfollowFollowerCount?follow_type=${1}`;
      const headers = {
        Authorization: `Bearer ${token}`,
        appid: "21",
      };
      const response = await Get(url, headers);
      if (response.success) {
        return response;
      } else {
        throw new Error(response.error.message);
      }
    } catch (error) {
      if (error.message === "Session expired. Please log in again.") {
        return { error: "Session expired. Please log in again." };
      } else {
        return { error: error.message || "An unexpected error occurred." };
      }
    }
  };
 
 
  const GetDataByProfileId = async (id) => {
    const token = localStorage.getItem("token");
    const url = `getdatabyprofileid?profile_id=${id}`;
    const headers = {
      Authorization: `Bearer ${token}`,
      appid: "21",
    };
 
    const response = await Get(url, headers);
    if (response.success) {
      return response;
    } else {
      console.log(response.error.message);
    }
 
  }
 
  const getOfflineMessages = async () => {
    const url = `get-bulk-message-list-in-web`;
    const headers = {
      appid: "21",
      'Content-Type': 'application/json'
    };
    return await Get(url, headers);
  };
 
  const getPlanlistWeb = async () => {
    const headers = {
      appid: "21",
      'Content-Type': 'application/json'
    };
    return await Get("planlistweb", headers);
  };
 
  const getHostListWeb = async (page) => {
    const token = localStorage.getItem("token");
    const headers = {
      appid: "22",
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    return await Get(`live-broadcast-list-web?page=${page}`, headers);
  };
 
  const getDataByProfileIdPublic = async (profileId) => {
    const url = `getdatabyprofileidpublic?profile_id=${profileId}`;
    const headers = {
      appid: "22",
      "Content-Type": "application/json"
    };
    return await Get(url, headers);
  }
 
  const offlineRechargeUserList = async () => {
    const token = localStorage.getItem("token");
    const url = `offline-recharge-user-list`;
    const headers = {
      Authorization: `Bearer ${token}`,
      appid: "21",
      "Content-Type": "application/json"
    };
    return await Get(url, headers);
  };
 
  return {
    GetProfileDetails,
    GetMyBalance,
    GetPlanlist,
    UpdateProfile,
    GetHostProfileDetails,
    FollowHost,
    GetFollowCount,
    generateTokenZegowithoutroom,
    GetDataByProfileId,
    getOfflineMessages,
    getPlanlistWeb,
    getHostListWeb,
    getDataByProfileIdPublic,
    offlineRechargeUserList
  };
};